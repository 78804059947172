import React, { FunctionComponent } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles.scss";

import { PortableText } from "../../types/SanityTypes";

// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";

import { RichTextSerializers } from "../../utils/richTextSerializers";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";

type TextBlockProps = {
  ctaLink: {
    asset: {
      asset: {
        url: string;
      };
    };
  };
  _rawTextBody: PortableText;
  ctaLabel: string;
};

const TextBlockExperimental: FunctionComponent<TextBlockProps> = ({ _rawTextBody, ctaLabel, ctaLink }) => {
  let isPDF = false;

  if (ctaLink) {
    isPDF = ctaLink.asset?.asset?.url.includes("pdf");
  }

  const { isoCountry } = useSiteMetadata();

  const dachClassName = isoCountry.toLowerCase() === 'de' ? 'dachWebsite' : '';

  return (
    <section className={`rx-c-textBlock ${dachClassName}`}>
      <Container>
        <Row>
          <Col md={12}>
            <div className="rx-c-textBlock-content">
              <BlockContent blocks={_rawTextBody} serializers={RichTextSerializers()} />

              { ctaLabel && (
                <div className="rx-c-textBlock-cta">
                  <a
                    className="btn-primary"
                    rel="noopener noreferrer"
                    href={ctaLink?.asset?.asset?.url}
                    download={isPDF ? true : false}
                  >
                    {ctaLabel}
                  </a>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TextBlockExperimental;
